// Import Images
import background from '../../../assets/Images/image-skilsmisse.png';
import BannerImageMobile from '../../../assets/Images/bannerMobile.png';

// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

// Page Data
const esgdata = {
  pageName: 'Esg',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'Sampension',
    pageDescription: 'Sampensions web site',
    bannerImage: background,
    mobile: BannerImageMobile,
    bannerContent: null,
    desktopTools: null,
  },
  pageContent: [
    {
      componentName: 'EsgTracker',
      componentData: {
        header: '<h1>Sådan arbejder vi med ESG',
        manchet:
          '<p class="intro">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tristique risus nec feugiat in fermentum. Nisl pretium fusce id velit ut tortor pretium viverra. Tortor posuere ac ut consequat semper viverra nam.</p>',
        categories: ['Eksklusion', 'Dialog', 'Ny investering', 'Overvågning'],
        esg: [
          {
            id: '1',
            date: '21. oktober 2019',
            category: ['Dialog', 'Ny investering'],
            header: 'Header #1',
            content:
              'Content #1',
          },
          {
            id: '2',
            date: '22. oktober 2019',
            category: ['Dialog', 'Eksklusion'],
            header: 'Header #2',
            content: '<b>Content #2 show me</b>',
          },
        ],
      },
    },
  ],
};

// Exports
export default esgdata;
