// Import Node Modules
import React from 'react';

// renderPage
import { renderPage } from '../../template/RenderPage';

// TempData
import esgdata from '../../mocks/pages/PageData/esg_data';

// Class
const esg = () => <div>{renderPage(esgdata)}</div>;

export default esg;
